import { useRouter } from 'next/router';
import Head from '@/components/Common/Head';
import { NotFound } from '@/components/Svg';
import styles from '@/styles/pages/AboutUs/AboutUs.module.scss';

export const Custom404 = () => {
  const router = useRouter();
  return (
    <>
      <Head
        title={'Not Found Page'}
        description={'Page not found! Please try again.'}
        imageUrl='https://cdn.landrocker.io/website_content/landing/metatag/Immutable_poster%20Version_04.jpg'
        isHome={false}
      />
      <div className={styles.notFoundSvg}>
        <div className={styles.svgs404}>
          <NotFound />
        </div>
        <div className={styles.notFoundText}>
          <p className='mb-4 text-xl font-bold'>Page Not Found</p>
        </div>
        <div className={styles.btnToHome}>
          <button onClick={() => router.push('/')} type='button'>
            Back To Home
          </button>
        </div>
      </div>
    </>
  );
};
export default Custom404;
